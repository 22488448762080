html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

* {
	margin: 0;
	padding: 0;
	outline: none;
}

*, *::before, *::after {
    box-sizing: border-box;
}

*[hidden] {
    display: none;
}

*+address, *+dl, *+fieldset, *+figure, *+ol, *+p, *+pre, *+ul, *+blockquote {
	margin-top: 20px;
}

body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
}

article, aside, dialog, figcaption, figure,
footer, header, hgroup, main, nav, section {
    display: block;
}

div {
    -webkit-tap-highlight-color: transparent;
}

a {
    color: #005aee;
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

a:hover {
  	color: #ee0000;
    text-decoration: none;
}

menu, ol, ul {
    list-style: none;
}

img, video, svg {
    height: auto;
    max-width: 100%;
	display: block;
}

button {
	cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td, th {
    padding: 0;
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.3;
	font-weight: 700;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 19px;
}

h4 {
	font-size: 16px;
}

h5 {
	font-size: 14px;
}

h6 {
	font-size: 13px;
}

.mid {
    margin: 0 auto;
    max-width: 1300px;
    padding-right: 1rem;
    padding-left: 1rem;
}

.checkbox, .radio {
	display: inline-block;
    height: 22px;
    width: 22px;
    overflow: hidden;
	margin-right: 15px;
    margin-top: -2px;
	cursor: pointer;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
	background-size: 10px;
    border: 1px solid #ccc;
    transition: .2s ease-in-out;
    transition-property: background-color,border;
}

.checkbox {
	border-radius: 5px;
}

.radio {
	border-radius: 50%;
}

.checkbox:hover, .radio:hover {
	border-color: #bababa;
}

.checkbox:checked, .radio:checked {
	background-color: #3b93d8;
    border-color: transparent;
	background-image: url(data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IkNhcGEgMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDM4NCI+PHBhdGggZD0iTTAgMjA4LjMzTDE3NS4zMyAzODQgNTEyIDQ3LjY2IDQ2My42NyAwIDE3NS4zMyAyODggNDcuNjYgMTYwLjMzeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
}

.input, .select, .textarea {
	border: 0 none;
	max-width: 100%;
    width: 100%;
    padding: 0 10px;
    background: #fff;
    border: 1px solid #ccc;
	border-radius: 5px;
    transition: .2s ease-in-out;
    transition-property: color,background-color,border;
	outline: 0;
}

.input, .select {
	height: 45px;
}

.textarea {
	padding-top: 7px;
    padding-bottom: 7px;
    vertical-align: top;
    resize: none;
}

.input:focus, .select:focus, .textarea:focus {
    background-color: #fff;
    border-color: #3b93d8;
}

.table {
	border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 20px;
}

.table th, .table td {
	padding: 16px 12px;
    text-align: left;
    vertical-align: bottom;
}

.table > :not(:first-child) > tr {
	border-top: 1px solid #ccc;
}

.table td {
	padding: 16px 12px;
	vertical-align: top;
}

.button {
	margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
	border-radius: 5px;
    vertical-align: middle;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    transition: .1s ease-in-out;
    transition-property: color,background-color,border-color;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

body,
html {
    max-width: 100vw;
    -webkit-font-smoothing: subpixel-antialiased;
}

body {
    font-family: -apple-system, system-ui, "Roboto", sans-serif;
    color: var(--tg-theme-text-color);
    background: var(--tg-theme-bg-color);
    padding-top: 60px;
    overflow: hidden;
}

::-webkit-input-placeholder {
    color: var(--tg-theme-hint-color);
}

:-moz-placeholder {
    color: var(--tg-theme-hint-color);
    opacity: 1;
}

::-moz-placeholder {
    color: var(--tg-theme-hint-color);
    opacity: 1;
}

:-ms-input-placeholder {
    color: var(--tg-theme-hint-color);
}

::-ms-input-placeholder {
    color: var(--tg-theme-hint-color);
}

::placeholder {
    color: var(--tg-theme-hint-color);
}

::-webkit-scrollbar {
    display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.categories-button,
.categories .active:after,
.footer-language-button {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.button {
    font-size: 15px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    padding: 0 15px;
    border-radius: 50px;
    background: var(--tg-theme-button-color);
    font-weight: 600;
    color: var(--tg-theme-button-text-color);
    display: inline-block;
}

.button:hover {
    background: var(--tg-theme-button-hover-color);
    color: var(--tg-theme-button-text-color);
}

.header {
    background: var(--tg-theme-bg-color);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
}

.header-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}

.header-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header-search {
    max-width: 600px;
    width: 100%;
    position: relative;
}

.header-search .input {
    padding-left: 45px;
}

.header-search svg {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 24px;
    transform: translateY(-50%);
    color: var(--tg-theme-hint-color);
    z-index: 1;
}

.preloader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader-image {
    width: 22px;
    fill: var(--tg-theme-text-color);
    animation: rotation 0.5s linear infinite;
}

.input {
    background: var(--tg-theme-secondary-bg-color);
    border: 0;
    border-radius: 9px;
    color: var(--tg-theme-text-color);
}

.input:focus {
    background: var(--tg-theme-secondary-bg-color);
}

.logo {
    width: 32px;
    margin-right: 15px;
}

.logo-name {
    width: 90px;
    fill: var(--tg-theme-text-color);
    margin-top: 3px;
}

.categories {
    overflow-x: scroll;
    overflow-y: hidden;
    background: var(--tg-theme-bg-color);
    margin-bottom: 40px;
    z-index: 9;
}

.categories-items {
    display: flex;
    align-items: center;
    min-width: fit-content;
    position: relative;
    border-bottom: 1px solid var(--tg-theme-stroke-separator);
}

.categories .active,
.categories .active:hover {
    color: var(--tg-theme-button-color);
}

.categories-button:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0%;
    height: 5px;
    background: var(--tg-theme-button-color);
}

.categories .active:after {
    width: 100%;
}

.categories-button:hover {
    color: var(--tg-theme-text-color);
}

.categories-button {
    position: relative;
    color: var(--tg-theme-hint-color);
    font-size: 18px;
    font-weight: 510;
    padding: 0 15px;
    line-height: 70px;
    cursor: pointer;
}

.content {
    margin-bottom: 20px;
}

.catalog {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -20px;
}

.catalog-item {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 15px 20px;
}

.catalog-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.catalog-badge {
    border-radius: 10px;
    display: flex;
    font-size: 11px;
    font-weight: 500;
    height: 16px;
    justify-content: center;
    line-height: 17px;
    padding: 0 6px;
    text-transform: uppercase;
    vertical-align: middle;
}

.catalog-verify {
    width: 15px;
    color: #4d96ff;
}

.catalog-verify + .catalog-badge {
    margin-left: 10px;
}

.catalog-badge-hot {
    background: rgba(223,137,80,.24);
    color: #df8950;
}

.catalog-avatar {
    flex: 0 0 80px;
    height: 80px;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 20px;
    position: relative;
}

.catalog-avatar-load {
    height: 100%;
    width: 100%;
    background: var(--tg-theme-secondary-bg-color);
}

.catalog-icon {
    position: relative;
}

.catalog-content {
    flex-grow: 1;
    width: 100%;
    margin-right: 5px;
}

.catalog-button {
    flex: 1;
}

.catalog-title {
    font-size: 19px;
    font-weight: 510;
    margin-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.catalog-description {
    color: var(--tg-theme-hint-color);
    overflow: hidden;
}

.catalog-button .skeleton {
    width: 100px;
    height: 30px;
}

.catalog-title .skeleton {
    width: 40%;
}

.main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.title {
    margin: 110px auto 45px auto;
    text-align: center;
}

.title-gradient {
    background: linear-gradient(89deg, #2b82eb, #1cc8ff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-feature-settings: "clig" off, "liga" off;
}

.results-animation {
    width: 150px;
    margin: 0 auto 20px auto;
}

.results-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.results-description {
    text-align: center;
}

.empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.pagination-preloader {
    width: 22px;
    fill: var(--tg-theme-text-color);
    animation: rotation 0.5s linear infinite;
}

.pagination-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    border-radius: 12px;
    background: var(--tg-theme-secondary-bg-color);
}

/* .footer {position: fixed; bottom: 0; left: 0; width: 100%; background: var(--tg-theme-bg-color);}
.footer-items {display: flex; align-items: center; justify-content: center; padding: 13px 0; border-top: 1px solid var(--tg-theme-stroke-separator);}
.footer-language {display: flex; align-items: center;}
.footer-language-button {padding: 0 15px; color: var(--tg-theme-hint-color); cursor: pointer; border-radius: 50px; line-height: 35px;}
.footer-language-button:hover {color: var(--tg-theme-text-color);}
.footer-language .active {color: var(--tg-theme-button-text-color); background: var(--tg-theme-button-color);} */

.skeleton {
    height: 15px;
    width: 90%;
    border-radius: 5px;
    margin: 4px 0;
    background: var(--tg-theme-secondary-bg-color);
}

@media (max-width: 992px) {
    .catalog-item {
        width: 100%;
        padding: 10px 0;
    }
    .catalog-title {
        font-size: 17px;
        margin-bottom: 0px;
    }
    .catalog-description {
        font-size: 14px;
    }
    .catalog {
        margin: 0;
    }
    .logo {
        width: 40px;
        margin-right: 25px;
    }
    .logo-name {
        display: none;
    }
    .categories {
        position: sticky;
        top: 60px;
        margin-bottom: 10px;
    }
    .input,
    .select {
        height: 40px;
    }
    .title {
        margin: 90px auto 15px;
    }
    .categories-button {
        font-size: 16px;
        line-height: 60px;
    }
    .footer-items {
        border-top: 0;
        padding: 7px 0;
    }
    .categories-items {
        border-bottom: 0;
    }
}

@media (max-width: 768px) {
    h1 {
        font-size: 29px;
    }
    .button {
        font-size: 14px;
    }
    .header-logo {
        display: none;
    }
    .header-search {
        max-width: none;
    }
    .catalog-avatar {
        flex: 0 0 60px;
        height: 60px;
    }
    .button:hover {
        background: var(--tg-theme-button-color);
        color: var(--tg-theme-button-text-color);
    }
    .categories-button:hover {
        color: var(--hint_color);
    }
}

@media (max-width: 480px) {
    .catalog-avatar {
        flex: 0 0 65px;
        height: 65px;
        margin-right: 15px;
    }
    .categories-button:hover {
        color: var(--tg-theme-hint-color);
    }
    .catalog-description {
        height: 43px;
    }
    .catalog-button {
        display: none;
    }
}

@keyframes rotation {
    0% {
        transform:rotate(0deg);
    }
    100% {
        transform:rotate(360deg);
    }
}

@keyframes modal {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}